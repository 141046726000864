"use client";

import clsx from "clsx";
import Link from "next/link";
import { useSelectedLayoutSegment } from "next/navigation";

export default function AuthLinks() {
	const segment = useSelectedLayoutSegment();

	return (
		<nav>
			<ul className={clsx("flex items-center justify-center space-x-4")}>
				{segment !== "signup" && (
					<li>
						<Link href="/signup">Create an account</Link>
					</li>
				)}
				{segment !== "forgotten" && (
					<li>
						<Link href="/forgotten">Forgotten your password?</Link>
					</li>
				)}
				{segment !== "signin" && (
					<li>
						<Link href="/signin">Sign in</Link>
					</li>
				)}
			</ul>
		</nav>
	);
}
